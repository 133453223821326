/**
 * facebook 分享地址
 */
export function getFacebookRoutePath(u) {
  let url = `https://www.facebook.com/sharer/sharer.php`
  if (u) {
    url = `${url}?u=${u}`
  }
  return url
}
/**
 * whatsApp app 分享地址
 */
export function getWhatsAppRoutePath(text) {
  let url = `whatsapp://send`
  if (text) {
    url = `${url}?text=${text}`
  }
  return url
}

/**
 * whatsApp web 分享地址
 */
export function getWhatsAppWehRoutePath(text) {
  let url = `https://api.whatsapp.com/send`
  if (text) {
    url = `${url}?text=${text}`
  }
  return url
}

/** 1000000商户下 whatsApp 分享地址 */
export function getNewWhatsAppWebRoutePath(text, phonelist) {
  let url = `https://wa.me/`
  if (phonelist && phonelist.length !== 0) {
    url = `${url}?text=${encodeURIComponent(text)}&phone=${phonelist.join(',')}`
  }
  return url
}

/**
 * twitter 分享地址
 */
export function getTwitterRoutePath(u) {
  let url = `https://twitter.com/intent/tweet`
  if (u) {
    url = `${url}?url=${u}`
  }
  return url
}
/**
 * telegram 分享地址
 */
export function getTelegramRoutePath(u) {
  let url = `https://t.me/share/url`
  if (u) {
    url = `${url}?url=${u}`
  }
  return url
}

/**
 * email 分享地址
 */
export function getEmailRoutePath(subject, body) {
  let url = `mailto:`
  if (subject) {
    url = `${url}?subject=${subject}${body ? `&body=${body}` : ''}`
  }
  return url
}

/**
 * 裂变活动地址
 */
export function getFissionRoutePath(id: string) {
  return `/marketing/fission-activity?id=${id}`
}

/**
 * 红包雨活动地址
 */
export function getRedEnvelopeRoutePath(id: string) {
  return `/marketing/red-envelope?id=${id}`
}
